import { default as indexa7UOpmk7lQMeta } from "/app/pages/cable-production/index.vue?macro=true";
import { default as indexQ6aaLm6uHyMeta } from "/app/pages/contacts/index.vue?macro=true";
import { default as indexliQlydtTYFMeta } from "/app/pages/erp/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexqaTJgriYEwMeta } from "/app/pages/manufacturing/index.vue?macro=true";
import { default as _91_46_46_46slug_93Cy8OQ3llwUMeta } from "/app/pages/news/[...slug].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as indexY3v9aMR2ziMeta } from "/app/pages/partnership/index.vue?macro=true";
import { default as indexOjFYGjc3X7Meta } from "/app/pages/progress/index.vue?macro=true";
import { default as indexdd0y1GP2UcMeta } from "/app/pages/projects/index.vue?macro=true";
import { default as index4PIiY458PxMeta } from "/app/pages/sales-area/index.vue?macro=true";
export default [
  {
    name: "cable-production",
    path: "/cable-production",
    meta: indexa7UOpmk7lQMeta || {},
    component: () => import("/app/pages/cable-production/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: indexQ6aaLm6uHyMeta || {},
    component: () => import("/app/pages/contacts/index.vue")
  },
  {
    name: "erp",
    path: "/erp",
    meta: indexliQlydtTYFMeta || {},
    component: () => import("/app/pages/erp/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "manufacturing",
    path: "/manufacturing",
    meta: indexqaTJgriYEwMeta || {},
    component: () => import("/app/pages/manufacturing/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug(.*)*",
    component: () => import("/app/pages/news/[...slug].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexoxV4GRc61hMeta || {},
    component: () => import("/app/pages/news/index.vue")
  },
  {
    name: "partnership",
    path: "/partnership",
    meta: indexY3v9aMR2ziMeta || {},
    component: () => import("/app/pages/partnership/index.vue")
  },
  {
    name: "progress",
    path: "/progress",
    component: () => import("/app/pages/progress/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexdd0y1GP2UcMeta || {},
    component: () => import("/app/pages/projects/index.vue")
  },
  {
    name: "sales-area",
    path: "/sales-area",
    meta: index4PIiY458PxMeta || {},
    component: () => import("/app/pages/sales-area/index.vue")
  }
]